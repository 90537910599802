import { h } from 'preact';
import { Route, Router } from 'preact-router';
import { useState } from 'preact/hooks';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Welcome from '../routes/welcome';

const App = () => {
    const [initialized, setInitialized] = useState(false);

    return (
        <div id="app">
            {!initialized ?
                <Welcome onClick={() => setInitialized(true)} />
                :
                <Router>
                    <Route path="/" component={Home} />
                </Router>
            }
        </div>
    )
};

export default App;
